<template>
  <div>
    <b-table-simple bordered small responsive>
      <b-thead>
        <b-tr>
          <b-th class="th-class-2" colspan="7"
            >INFORME DE RESULTADOS DE LOGROS</b-th
          >
        </b-tr>
        <b-tr>
          <b-th class="th-class" :colspan="2">
            {{
              this.ra_macro
                ? this.ra_macro.study_units.length > 0
                  ? $getVisibleNames(
                      "teaching.ramicro",
                      false,
                      "RA Micro"
                    ).toUpperCase()
                  : ""
                : $getVisibleNames(
                    "teaching.ramicro",
                    false,
                    "RA Micro"
                  ).toUpperCase()
            }}{{
              this.ra_macro &&
              institution &&
              institution.show_evaluation_criteria_macro
                ? this.ra_macro.study_units.length > 0 &&
                  this.ra_macro.evaluation_criterias_macro.length > 0 &&
                  institution &&
                  institution.show_evaluation_criteria_macro
                  ? " / "
                  : ""
                : ""
            }}
            {{
              this.ra_macro &&
              institution &&
              institution.show_evaluation_criteria_macro
                ? this.ra_macro.evaluation_criterias_macro.length > 0 &&
                  institution &&
                  institution.show_evaluation_criteria_macro
                  ? "CE MACRO"
                  : ""
                : ""
            }}
          </b-th>
          <b-th class="th-class-2" :colspan="2">
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                false,
                "Criterio de Evaluación"
              ).toUpperCase()
            }}</b-th
          >
          <b-th class="th-class-2" :colspan="3">LOGROS</b-th>
        </b-tr>
        <b-tr>
          <b-th class="th-class"> ENUNCIADO </b-th>
          <b-th class="th-class"> PONDERACIÓN </b-th>
          <b-th class="th-class"> ENUNCIADO </b-th>
          <b-th class="th-class"> PONDERACIÓN </b-th>
          <b-th class="th-class">
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                false,
                "Criterio de Evaluación"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="th-class">
            {{
              this.ra_macro
                ? this.ra_macro.study_units.length > 0
                  ? $getVisibleNames(
                      "teaching.ramicro",
                      false,
                      "RA Micro"
                    ).toUpperCase()
                  : ""
                : $getVisibleNames(
                    "teaching.ramicro",
                    false,
                    "RA Micro"
                  ).toUpperCase()
            }}{{
              this.ra_macro &&
              institution &&
              institution.show_evaluation_criteria_macro
                ? this.ra_macro.study_units.length > 0 &&
                  this.ra_macro.evaluation_criterias_macro.length > 0 &&
                  institution &&
                  institution.show_evaluation_criteria_macro
                  ? " / "
                  : ""
                : ""
            }}
            {{
              this.ra_macro &&
              institution &&
              institution.show_evaluation_criteria_macro
                ? this.ra_macro.evaluation_criterias_macro.length > 0 &&
                  institution &&
                  institution.show_evaluation_criteria_macro
                  ? "CE MACRO"
                  : ""
                : ""
            }}
          </b-th>
          <b-th
            class="th-class"
            v-if="institution && institution.show_ra_macro"
          >
            {{
              $getVisibleNames(
                "teaching.ramacro",
                false,
                "RA Macro"
              ).toUpperCase()
            }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="ra_macro != null">
          <!-- <template v-for="(ra_macro, index) in list"> -->
          <template v-if="ra_macro.study_units.length > 0">
            <template v-for="(study_unit, index2) in ra_macro.study_units">
              <template v-if="study_unit.evaluation_criterias_micro.length > 0">
                <b-tr
                  v-for="(
                    evaluation_criteria, index3
                  ) in study_unit.evaluation_criterias_micro"
                  :key="
                    'study_unit:' + index2 + 'evaluation_criteria:' + index3
                  "
                >
                  <!-- 'ra_macro:' +
                      index + -->
                  <b-td
                    class="td-class"
                    :rowspan="study_unit.evaluation_criterias_micro.length"
                    v-if="index3 == 0"
                  >
                    <span
                      class="study-unit"
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        study_unit.full_sentence
                      "
                    >
                      {{ study_unit.name }}
                    </span>
                  </b-td>
                  <b-td
                    class="td-class"
                    :rowspan="study_unit.evaluation_criterias_micro.length"
                    v-if="index3 == 0"
                  >
                    <span v-if="!matter.automatic_achievement_weighing">
                      {{ study_unit.weighing | Round }} %
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <b-td class="td-class text-justify pl-2">
                    <span class="d-flex">
                      <span class="evaluation-criteria-order">
                        {{ study_unit.order }}.{{ evaluation_criteria.order }}
                      </span>
                      <SentenceContainer
                        :Large="55"
                        :Sentence="evaluation_criteria"
                        :boundedTextContainer="true"
                      ></SentenceContainer>
                    </span>
                  </b-td>
                  <b-td class="td-class">
                    <span v-if="!matter.automatic_achievement_weighing">
                      {{ evaluation_criteria.weighing | Round }} %
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <b-td
                    class="td-class"
                    v-bind:class="
                      evaluation_criteria.achievement == 0
                        ? ''
                        : evaluation_criteria.achievement <= 59
                        ? 'bg-red'
                        : evaluation_criteria.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                  >
                    {{ evaluation_criteria.achievement | Round }} %
                  </b-td>
                  <b-td
                    class="td-class"
                    v-bind:class="
                      study_unit.achievement == 0
                        ? ''
                        : study_unit.achievement <= 59
                        ? 'bg-red'
                        : study_unit.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                    :rowspan="study_unit.evaluation_criterias_micro.length"
                    v-if="index3 == 0"
                  >
                    {{ study_unit.achievement | Round }} %
                  </b-td>
                  <b-td
                    class="td-class"
                    v-bind:class="
                      ra_macro.achievement == 0
                        ? ''
                        : ra_macro.achievement <= 59
                        ? 'bg-red'
                        : ra_macro.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                    :rowspan="
                      rowspanCount(ra_macro) +
                      ra_macro.evaluation_criterias_macro.length
                    "
                    v-if="
                      index2 == 0 &&
                      index3 == 0 &&
                      institution &&
                      institution.show_ra_macro
                    "
                  >
                    {{ ra_macro.achievement | Round }} %
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <!-- 'ra_macro:' + index + -->
                <b-tr :key="'study_unit:' + index2">
                  <b-td class="td-class">
                    <span
                      class="study-unit"
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        study_unit.full_sentence
                      "
                    >
                      {{ study_unit.name }}
                    </span>
                  </b-td>
                  <b-td class="td-class">
                    <span v-if="!matter.automatic_achievement_weighing">
                      {{ study_unit.weighing | Round }} %
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <b-td>-</b-td>
                  <b-td>-</b-td>
                  <b-td>-</b-td>
                  <b-td
                    v-bind:class="
                      study_unit.achievement == 0
                        ? ''
                        : study_unit.achievement <= 59
                        ? 'bg-red'
                        : study_unit.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                    >{{ study_unit.achievement | Round }} %</b-td
                  >
                  <b-td
                    class="td-class"
                    :rowspan="
                      rowspanCount(ra_macro) +
                      ra_macro.evaluation_criterias_macro.length
                    "
                    v-bind:class="
                      ra_macro.achievement == 0
                        ? ''
                        : ra_macro.achievement <= 59
                        ? 'bg-red'
                        : ra_macro.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                    v-if="
                      index2 == 0 && institution && institution.show_ra_macro
                    "
                  >
                    {{ ra_macro.achievement | Round }} %
                  </b-td>
                </b-tr>
              </template>
            </template>
            <template
              v-if="institution && institution.show_evaluation_criteria_macro"
            >
              <template
                v-for="(
                  evaluation_criteria, index3
                ) in ra_macro.evaluation_criterias_macro"
              >
                <!-- 'ra_macro:' + index +  -->
                <b-tr :key="'evaluation_criteria:' + index3">
                  <b-td class="td-class">
                    <span
                      class="ce-macro"
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        evaluation_criteria.full_sentence
                      "
                    >
                      CE MACRO {{ evaluation_criteria.order }}
                    </span>
                  </b-td>
                  <b-td class="td-class">
                    <span v-if="!matter.automatic_achievement_weighing">
                      {{ evaluation_criteria.weighing | Round }} %
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <b-td class="td-class text-left pl-2">
                    <SentenceContainer
                      :Large="55"
                      :Sentence="evaluation_criteria"
                      :boundedTextContainer="true"
                    ></SentenceContainer>
                  </b-td>
                  <b-td>N/A</b-td>
                  <b-td>N/A</b-td>
                  <b-td
                    class="td-class"
                    v-bind:class="
                      evaluation_criteria.achievement == 0
                        ? ''
                        : evaluation_criteria.achievement <= 59
                        ? 'bg-red'
                        : evaluation_criteria.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                    >{{ evaluation_criteria.achievement | Round }} %</b-td
                  >
                </b-tr>
              </template>
            </template>
          </template>
          <template v-else>
            <template v-if="ra_macro.evaluation_criterias_macro.length == 0">
              <b-tr>
                <b-td colspan="7" class="td-class"
                  >No hay datos que mostrar</b-td
                >
              </b-tr>
            </template>
            <template
              v-if="institution && institution.show_evaluation_criteria_macro"
            >
              <template
                v-for="(
                  evaluation_criteria, index3
                ) in ra_macro.evaluation_criterias_macro"
              >
                <!-- 'ra_macro:' + index +  -->
                <b-tr :key="'evaluation_criteria:' + index3">
                  <b-td class="td-class"
                    ><span
                      v-b-tooltip.v-secondary.noninteractive.hover.right="
                        evaluation_criteria.full_sentence
                      "
                    >
                      CE MACRO {{ evaluation_criteria.order }}
                    </span>
                  </b-td>
                  <b-td class="td-class">
                    <span v-if="!matter.automatic_achievement_weighing">
                      {{ evaluation_criteria.weighing | Round }} %
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <b-td class="td-class text-left pl-2">
                    <SentenceContainer
                      :Large="55"
                      :Sentence="evaluation_criteria"
                      :boundedTextContainer="true"
                    ></SentenceContainer>
                  </b-td>
                  <b-td>N/A</b-td>
                  <b-td>N/A</b-td>
                  <b-td
                    class="td-class"
                    v-bind:class="
                      evaluation_criteria.achievement == 0
                        ? ''
                        : evaluation_criteria.achievement <= 59
                        ? 'bg-red'
                        : evaluation_criteria.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                    >{{ evaluation_criteria.achievement | Round }} %</b-td
                  >
                  <b-td
                    class="td-class"
                    v-if="
                      index3 == 0 && institution && institution.show_ra_macro
                    "
                    v-bind:class="
                      ra_macro.achievement == 0
                        ? ''
                        : ra_macro.achievement <= 59
                        ? 'bg-red'
                        : ra_macro.achievement <= 84
                        ? 'bg-yellow'
                        : 'bg-green'
                    "
                    :rowspan="ra_macro.evaluation_criterias_macro.length"
                  >
                    {{ ra_macro.achievement | Round }} %
                  </b-td>
                </b-tr>
              </template>
            </template>
          </template>
          <!-- </template> -->
        </template>
        <template v-else>
          <b-tr>
            <b-td colspan="7" class="td-class">No hay datos que mostrar</b-td>
          </b-tr>
        </template></b-tbody
      ></b-table-simple
    >
    <template v-if="ra_macro != null">
      <b-card
        no-header
        no-body
        class="text-left footer-card"
        v-if="institution && institution.show_ra_macro"
      >
        <span class="card-custom-title">
          {{
            $getVisibleNames(
              "teaching.ramacro",
              false,
              "Resultado de Aprendizaje Macro"
            ).toUpperCase()
          }}
        </span>
        <b-list-group flush>
          <b-list-group-item>
            <b-tr>
              <b-td>
                <SentenceContainer
                  :Large="55"
                  :Sentence="ra_macro"
                  :boundedTextContainer="false"
                ></SentenceContainer
              ></b-td>
            </b-tr>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card
        v-if="ra_macro.study_units.length > 0"
        no-header
        no-body
        class="text-left footer-card mt-2"
      >
        <span class="card-custom-title">
          {{
            $getVisibleNames(
              "teaching.ramicro",
              false,
              ra_macro.study_units.length > 1
                ? "Resultados de Aprendizaje Micro"
                : "Resultado de Aprendizaje Micro"
            ).toUpperCase()
          }}
        </span>
        <b-list-group flush>
          <b-list-group-item
            v-for="item in ra_macro.study_units"
            :key="item.id"
          >
            <div class="d-flex">
              <span class="study-unit-name mr-1">{{ item.name }}:</span>
              <div class="ml-1 ra-micro-sentence">
                {{ item.order }}.
                <SentenceContainer
                  :Large="55"
                  class="evaluation-criteria-order-2 ml-1"
                  :Sentence="item"
                  :boundedTextContainer="false"
                ></SentenceContainer>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AssessmentTable",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    user_id: {
      type: Number,
      required: true,
    },
    matter: {
      type: Object,
      required: true,
    },
    section_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ra_macro: null,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  methods: {
    rowspanCount(ra_macro) {
      let count = 0;
      ra_macro.study_units.forEach((study_unit) => {
        if (study_unit.evaluation_criterias_micro.length > 0)
          study_unit.evaluation_criterias_micro.forEach(() => {
            count += 1;
          });
        else count += 1;
      });
      return count;
    },
    fetchAssessmentTable() {
      this.$restful
        .Get(
          `/teaching/achievement_for_student_in_section/?section=${this.section_id}&student=${this.user_id}`
        )
        .then((response) => {
          this.ra_macro = response;
        });
      // this.ra_macro = {
      //   id: 1,
      //   achievement: 100.0,
      //   verb: "Memorizar",
      //   action: "Memorizar",
      //   content: "aaaaaaaaaaaaa",
      //   condition: "aaaaaaaaaa",
      //   full_sentence: "Memorizar aaaaaaaaaaaaa aaaaaaaaaa",
      //   evaluation_criterias_macro: [
      //     {
      //       id: 1,
      //       achievement: 100.0,
      //       weighing: 50,
      //       verb: "Enumerar",
      //       action: "Enumerar",
      //       content: "bbbbbbb",
      //       condition: "bbbbbbbbbbb",
      //       full_sentence: "Enumerar bbbbbbb bbbbbbbbbbb",
      //     },
      //     {
      //       id: 2,
      //       achievement: 93.33333333333333,
      //       weighing: 50,
      //       verb: "Enumerar",
      //       action: "Enumerar",
      //       content: "aaaaaaaaa",
      //       condition: "aaaaaaaaaaa",
      //       full_sentence: "Enumerar aaaaaaaaa aaaaaaaaaaa",
      //     },
      //   ],
      //   study_units: [
      //     {
      //       id: 20,
      //       action: "Definir",
      //       content: "aaaaaaaaaaaaaaaaaaa",
      //       condition: "aaaaaaaaaaaaaa",
      //       full_sentence: "Definir aaaaaaaaaaaaaaaaaaa aaaaaaaaaaaaaa",
      //       name: "Unidad 1",
      //       order: 1,
      //       weighing: 50,
      //       achievement: 97.5,
      //       verb: "Definir",
      //       evaluation_criterias_micro: [
      //         {
      //           id: 1,
      //           achievement: 95.0,
      //           weighing: 50,
      //           verb: "Describir",
      //           action: "Describir",
      //           content: "aaaaa",
      //           condition: "aaaaaaaaaa",
      //           full_sentence: "Describir aaaaa aaaaaaaaaa",
      //         },
      //         {
      //           id: 2,
      //           achievement: 100.0,
      //           weighing: 50,
      //           verb: "Describir",
      //           action: "Describir",
      //           content: "bbbbbbbbbbbb",
      //           condition: "bbbbbbbbbbbbbbb",
      //           full_sentence: "Describir bbbbbbbbbbbb bbbbbbbbbbbbbbb",
      //         },
      //       ],
      //     },
      //     {
      //       id: 21,
      //       action: "Conocer",
      //       content: "bbbbbbbbbbbbbbb",
      //       condition: "bbbbbbbbbbbbb",
      //       full_sentence: "Conocer bbbbbbbbbbbbbbb bbbbbbbbbbbbb",
      //       name: "Unidad 2",
      //       order: 2,
      //       weighing: 50,
      //       achievement: 98.0,
      //       verb: "Conocer",
      //       evaluation_criterias_micro: [
      //         {
      //           id: 3,
      //           achievement: 96.0,
      //           weighing: 50,
      //           verb: "Describir",
      //           action: "Describir",
      //           content: "cccccccccccc",
      //           condition: "ccccccccccccc",
      //           full_sentence: "Describir cccccccccccc ccccccccccccc",
      //         },
      //         {
      //           id: 4,
      //           achievement: 100.0,
      //           weighing: 50,
      //           verb: "Describir",
      //           action: "Describir",
      //           content: "dddddddddddd",
      //           condition: "ddddddddd",
      //           full_sentence: "Describir dddddddddddd ddddddddd",
      //         },
      //       ],
      //     },
      //   ],
      // };
    },
  },
  created() {
    this.fetchAssessmentTable();
  },
};
</script>
<style scoped>
table thead >>> .th-class {
  background-color: var(--primary-color) !important;
  vertical-align: middle !important;
}
table thead >>> .th-class-2 {
  background-color: #c1dde8 !important;
  vertical-align: middle !important;
}
.td-class {
  vertical-align: middle !important;
}
.evaluation-criteria-order {
  font-size: 11pt;
  /* transform: translateY(-2%); */
}
.ra-micro-sentence {
  display: flex;
  font-size: 11pt;
}
.evaluation-criteria-order-2 {
  font-size: 11pt;
  /* transform: translateY(-108%); */
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
.study-unit,
.ce-macro {
  transition: ease-in-out 0.3s;
  text-decoration: underline;
  font-size: 11pt;
}
.study-unit:hover,
.ce-macro {
  color: #0d1525;
  transition: 0.2s;
  cursor: help;
}
.footer-card {
  padding: 0;
  background: var(--primary-color);
}
.card-custom-title {
  padding: 0.5em;
  color: var(--secondary-font-color);
  font-weight: bold;
}
.study-unit-name {
  font-size: 11pt;
  width: 15%;
}
</style>